#cv {
  --header-height: 45px;
  --main-height: calc(100vh - 45px);
}
#cv * {
  font-family: Montserrat, sans-serif, "Segoe UI", Tahoma, Geneva, Verdana;
  transition: background-color 300ms ease;
}
#cv a:first-of-type {
  text-decoration: none;
  color: inherit;
}
#cv .anchor {
  display: block;
  position: relative;
  top: -80px;
  visibility: hidden;
}
#cv .comp_EducationBox {
  width: 100%;
  max-width: 800px;
  padding: 0.3rem 0.8rem;
  border-left: 0.15rem solid rgba(255, 255, 255, 0.849);
  color: white;
  letter-spacing: 0.03rem;
}
#cv .comp_EducationBox:not(:last-child) {
  margin-bottom: 2rem;
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .comp_EducationBox:not(:last-child) {
    margin-bottom: 1rem;
  }
}
#cv .comp_EducationBox__school {
  font-size: 1.2rem;
  font-weight: normal;
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .comp_EducationBox__school {
    font-size: 1rem;
  }
}
#cv .comp_EducationBox__course {
  color: var(--second-color);
  font-weight: bold;
  font-size: 1.1rem;
  margin: 0.2rem 0;
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .comp_EducationBox__course {
    font-size: 0.9rem;
  }
}
#cv .comp_EducationBox__year {
  font-style: italic;
  font-size: 0.9rem;
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .comp_EducationBox__year {
    font-size: 0.7rem;
  }
}
#cv .comp_progressbar {
  width: calc(50% - 0.4rem);
  max-width: 11rem;
  display: inline-block;
}
@media screen and (min-width: 450px) and (max-width: 800px) {
  #cv .comp_progressbar {
    width: calc(33% - 0.4rem);
  }
}
@media screen and (min-width: 600px) and (max-width: 800px) {
  #cv .comp_progressbar {
    width: calc(25% - 0.56em);
  }
}
#cv .comp_progressbar__icon {
  font-size: 2rem;
  display: inline;
}
#cv .comp_progressbar__label {
  color: var(--progbar-title);
  display: inline;
  position: relative;
  top: -8px;
  left: 5px;
  font-size: 1rem;
  letter-spacing: 0.05rem;
  font-weight: bold;
}
#cv .comp_progressbar__level {
  color: var(--text-color);
  opacity: 0.3;
  font-style: italic;
  margin-bottom: 0.2rem;
  margin-top: -0.1rem;
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .comp_progressbar__level {
    font-size: 0.8rem;
  }
}
#cv .comp_progressbar__outer {
  position: relative;
  height: 0.6rem;
  background-color: rgb(228, 228, 228);
}
#cv .comp_progressbar__inner {
  position: absolute;
  height: 0.6rem;
  background: linear-gradient(to right, var(--main-color), var(--progbar-end));
}
#cv .comp_progressbar__2550 {
  border: 0.15rem white;
  border-style: none solid;
  position: absolute;
  left: 25%;
  height: 0.6rem;
  width: 25%;
}
#cv .comp_progressbar__75 {
  border: 0.15rem white;
  border-style: none solid none none;
  position: absolute;
  height: 0.6rem;
  width: 75%;
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .comp_progressbar__icon {
    font-size: 1.5rem;
  }
  #cv .comp_progressbar__label {
    top: -5px;
    left: 5px;
    font-size: 0.8rem;
  }
}
#cv .comp_tool {
  background-color: var(--second-color);
  box-shadow: 0 0 0.2rem var(--main-color);
  border-radius: 0.4rem;
  padding: 0.25rem 0.3rem 0.1rem 0.3rem;
}
@media (min-width: 801px) {
  #cv .comp_tool {
    font-size: 1rem;
    padding: 0.3rem 0.4rem 0.2rem 0.4rem;
  }
}
#cv .comp_tool svg {
  margin-right: 0.3rem;
  margin-left: 0.1rem;
}
#cv .comp_tool span {
  float: right;
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .comp_tool span {
    font-size: 0.8rem;
    margin-top: 0.1rem;
  }
}
#cv .comp_schoolprogress {
  position: absolute;
  width: 90vw;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 4%;
  text-align: center;
}
#cv .comp_schoolprogress__outline {
  position: absolute;
  width: 100%;
  height: 0.7rem;
  background-color: rgba(206, 206, 206, 0.514);
  border-radius: 1rem;
}
#cv .comp_schoolprogress__fill {
  position: absolute;
  width: 0%;
  height: 0.7rem;
  background-color: var(--main-color);
  border-radius: 1rem;
  transition: width 700ms ease-out;
}
#cv .comp_schoolprogress svg {
  position: absolute;
  bottom: 0.4rem;
  right: 1%;
  font-size: 1.5rem;
  color: var(--main-color);
}
#cv .comp_schoolprogress p {
  position: absolute;
  bottom: 0.7rem;
  font-size: 1rem;
  width: 100%;
  text-align: center;
  color: white;
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .comp_schoolprogress p {
    font-size: 0.9rem;
  }
}
@media (max-width: 500px) {
  #cv .comp_schoolprogress p {
    font-size: 0.75rem;
  }
}
#cv .comp_ExperienceBox {
  padding: 0.5rem 0.8rem;
}
#cv .hidden {
  display: none;
  opacity: 0;
}
#cv .util_themeselector {
  position: fixed;
  top: 3rem;
  right: 0.9rem;
  z-index: 9;
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .util_themeselector {
    top: 3.5rem;
  }
}
#cv .util_themeselector svg {
  padding: 0.4rem;
  background-color: var(--second-color);
  border-radius: 100px;
  font-size: 3rem;
  color: var(--main-color);
}
#cv .util_themeselector svg:hover {
  cursor: pointer;
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .util_themeselector svg {
    font-size: 2.5rem;
    padding: 0.25rem;
  }
}
#cv .util_themeselector__options {
  display: none;
  background-color: var(--second-color);
  border-radius: 100px;
  padding: 0.2rem 0.25rem;
}
#cv .util_themeselector__options__opt1, #cv .util_themeselector__options__opt2, #cv .util_themeselector__options__opt3 {
  width: 2rem;
  height: 2rem;
  margin: 0.2rem;
  border: 1px solid var(--main-color);
  border-radius: 50px;
}
#cv .util_themeselector__options__opt1:hover, #cv .util_themeselector__options__opt2:hover, #cv .util_themeselector__options__opt3:hover {
  cursor: pointer;
  filter: brightness(80%);
}
#cv .util_themeselector__options__opt1 {
  background-color: rgb(28, 160, 212);
}
#cv .util_themeselector__options__opt2 {
  background-color: rgb(169, 38, 38);
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .util_themeselector__options__opt2 {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}
#cv .util_themeselector__options__opt3 {
  background-color: rgb(0, 128, 128);
}
@media (max-width: 800px), (max-height: 400px) {
  #cv p {
    font-size: 0.9rem;
  }
}
@media (min-width: 801px) and (max-width: 1100px) and (min-height: 401px) {
  #cv p,
  #cv li {
    font-size: 0.9rem;
  }
}
#cv #header {
  height: var(--header-height);
  position: fixed;
  top: 0;
  width: 100%;
  color: white;
  padding: 10px;
  z-index: 10;
  transition: background-color 400ms ease;
}
#cv #header p {
  font-size: 1.4rem;
  float: left;
  margin-top: -3px;
}
@media (min-width: 801px) and (max-width: 1100px) and (min-height: 401px) {
  #cv #header p {
    font-size: 1rem;
    margin-top: 2px;
  }
}
#cv #header p span {
  transition: all 70ms linear;
}
#cv #header p span:hover {
  color: var(--main-color);
}
#cv #header ul {
  float: right;
  margin-top: 1px;
}
@media (min-width: 801px) and (max-width: 1100px) and (min-height: 401px) {
  #cv #header ul {
    position: absolute;
    right: 0;
    top: 7px;
  }
}
#cv #header ul li {
  display: inline-block;
  padding: 0 4px;
  font-size: 1.1rem;
  margin-right: 10px;
}
#cv #header ul li:last-of-type {
  margin-right: 20px;
}
@media (min-width: 801px) and (max-width: 1100px) and (min-height: 401px) {
  #cv #header ul li {
    font-size: 1rem;
    margin-top: 4px;
    margin-right: 1px;
  }
  #cv #header ul li:last-of-type {
    margin-right: 5px;
  }
}
#cv #header ul li:hover {
  text-decoration: underline;
  cursor: pointer;
}
#cv #header ul li::before {
  content: " / ";
}
@media (max-width: 800px), (max-height: 400px) {
  #cv #header {
    background-color: rgba(255, 255, 255, 0.35);
  }
  #cv #header p {
    display: none;
  }
  #cv #header ul {
    float: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  #cv #header ul li {
    display: inline-block;
    color: black;
    padding: 5px 2px;
    font-size: 0.9rem;
    margin-right: 0px;
    margin-top: 0px;
  }
}
@media (max-width: 800px) and (max-width: 500px), (max-height: 400px) and (max-width: 500px) {
  #cv #header ul li {
    font-size: 0.8rem;
  }
  #cv #header ul li:nth-child(5) {
    display: none;
  }
}
@media (max-width: 800px), (max-height: 400px) {
  #cv #header ul li:last-of-type {
    margin-right: 0px;
  }
  #cv #header ul li::before {
    content: "";
  }
}
@media (max-width: 800px), (max-height: 400px) {
  #cv #header .li-mobile-white {
    color: white;
  }
}
#cv .landing {
  height: 100vh;
  padding-top: var(--header-height);
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (min-width: 801px) {
  #cv .landing {
    background-image: linear-gradient(to bottom, rgba(44, 44, 44, 0.3960784314), rgba(27, 46, 54, 0.6705882353)), url(../images/unsplash-landing-bg2.webp);
    background-position: 50% 50%;
    background-attachment: fixed;
  }
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .landing {
    background-image: linear-gradient(to bottom, rgba(44, 44, 44, 0.1529411765), rgba(31, 31, 31, 0.7411764706)), url(../images/unsplash-landing-bg-mobile.webp);
    background-position: 50% 50%;
  }
}
#cv .landing__box {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 32vh;
  cursor: default;
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .landing__box {
    padding-top: 30vh;
  }
}
@media screen and (max-height: 400px) {
  #cv .landing__box {
    padding-top: 20vh;
  }
}
#cv .landing__box__title {
  color: white;
  font-size: 4rem;
  font-weight: bold;
}
@media (min-width: 801px) {
  #cv .landing__box__title > span {
    transition: all 50ms linear;
  }
  #cv .landing__box__title > span:hover, #cv .landing__box__title > span:hover + span {
    color: var(--main-color);
  }
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .landing__box__title {
    font-size: 2rem;
  }
}
#cv .landing__box__namemobile {
  color: white;
  font-size: 1.6rem;
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .landing__box__namemobile {
    font-size: 1.1rem;
  }
}
#cv .landing__box__line1, #cv .landing__box__line2 {
  width: 8rem;
  height: 3px;
  margin: 0.8rem auto;
  background-color: var(--main-color);
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .landing__box__line1, #cv .landing__box__line2 {
    width: 5rem;
  }
}
#cv .landing__box__quote {
  color: rgba(255, 255, 255, 0.75);
  font-style: italic;
  font-size: 1.1rem;
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .landing__box__quote {
    font-size: 0.8rem;
  }
}
#cv .layout-child {
  padding: 5vh 5vw;
  min-height: 290px;
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .layout-child {
    padding: 3.3rem 1rem;
  }
}
#cv .layout-child__left {
  width: 20%;
  display: inline-block;
  position: relative;
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .layout-child__left {
    width: 100%;
    padding-left: 6rem;
    margin-bottom: 1.2rem;
  }
}
#cv .layout-child__left h2 {
  font-size: 1.4rem;
}
@media (min-width: 801px) and (max-width: 1100px) and (min-height: 401px) {
  #cv .layout-child__left h2 {
    font-size: 1.2rem;
  }
}
#cv .layout-child__left__line {
  height: 3px;
  width: 8.6rem;
  margin: 5px 0;
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .layout-child__left__line {
    width: 12rem;
  }
}
#cv .layout-child__left__image {
  position: absolute;
  left: -54px;
  top: -2px;
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .layout-child__left__image {
    top: -3.4rem;
    left: -1.8rem;
  }
}
#cv .layout-child__left__image > img {
  width: 210px;
  height: 210px;
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .layout-child__left__image > img {
    width: 135px;
    height: 135px;
  }
}
#cv .layout-child__right {
  width: 80%;
  max-width: 800px;
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .layout-child__right {
    width: 100%;
  }
}
#cv .about {
  background-color: var(--second-color);
  letter-spacing: 0.01rem;
}
#cv .about .layout-child__left {
  color: var(--main-color);
}
#cv .about .layout-child__left__line {
  background-color: var(--main-color);
}
#cv .about .layout-child__left img {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  position: absolute;
  top: 3.6rem;
  left: 3rem;
}
@media (min-width: 801px) and (max-width: 1100px) and (min-height: 401px) {
  #cv .about .layout-child__left img {
    top: 3rem;
    width: 130px;
    height: 130px;
  }
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .about .layout-child__left img {
    top: 1.6rem;
    left: 1.6rem;
    width: 80px;
    height: 80px;
  }
}
#cv .about__right {
  color: var(--text-color);
}
#cv .about__right h3 {
  margin-bottom: 0.3rem;
}
#cv .about__right a {
  text-decoration: underline;
  color: var(--main-color);
}
@media (max-width: 500px) {
  #cv .about__right p {
    font-size: 0.8rem;
  }
}
#cv .about__divider {
  width: 12rem;
  border-bottom: 1px solid var(--main-color);
  margin: 2rem 0;
}
#cv .education {
  background-color: var(--main-color);
}
#cv .education .layout-child__left {
  color: var(--second-color);
}
#cv .education .layout-child__left__line {
  background-color: var(--second-color);
}
#cv .skills {
  background-color: var(--second-color);
  color: var(--main-color);
}
#cv .skills .layout-child__left__line {
  background-color: var(--main-color);
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .skills .layout-child__left__image img {
    width: 130px;
  }
}
#cv .skills .layout-child__right {
  max-width: 900px;
}
#cv .skills h3 {
  font-weight: normal;
  font-size: 1.3rem;
  margin-left: -0.1rem;
  color: var(--text-color);
  margin-bottom: 0.7rem;
}
#cv .skills__category {
  display: flex;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  gap: 0.7rem;
}
@media (min-width: 801px) {
  #cv .skills__category {
    margin-bottom: 3.5rem;
    gap: 1rem 1.5rem;
  }
}
#cv .skills__category-tools {
  display: flex;
  margin-bottom: 2rem;
  margin-top: 0.5rem;
  flex-wrap: wrap;
  gap: 0.4rem;
}
@media (min-width: 801px) {
  #cv .skills__category-tools {
    gap: 0.7rem;
  }
}
#cv .skills__category-tools__d {
  display: block;
  height: 0.3rem;
  width: 100%;
}
#cv .portfolio {
  background-color: var(--main-color);
}
#cv .portfolio .layout-child__left {
  color: var(--second-color);
}
#cv .portfolio .layout-child__left__line {
  background-color: var(--second-color);
}
#cv .portfolio .layout-child__right {
  max-width: 1000px;
}
#cv .portfolio__container {
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 2rem;
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .portfolio__container {
    gap: 0.5rem;
  }
}
#cv .projects-card {
  position: relative;
  width: 20rem;
  max-height: 8.2rem;
  padding: 0.75rem 0.75rem 3rem 0.75rem;
  background-color: var(--second-color);
  transition: max-height 900ms, width 700ms;
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .projects-card {
    margin: 0 auto;
  }
}
#cv .projects-card--expanded {
  display: block;
  width: 100%;
  max-height: 150rem;
  padding-bottom: 4rem;
}
@media (min-width: 801px) {
  #cv .projects-card--expanded {
    width: 100%;
  }
}
#cv .projects-card__body {
  transition: opacity 500ms;
  font-size: 0.9rem;
  opacity: 0;
}
@media (max-width: 500px) {
  #cv .projects-card__body p,
  #cv .projects-card__body li {
    font-size: 0.8rem;
  }
}
@media (min-width: 801px) {
  #cv .projects-card__body {
    font-size: 1rem;
  }
}
#cv .projects-card__body--hidden {
  display: none;
}
#cv .projects-card__body--visible {
  opacity: 1;
}
#cv .projects-card__body h4 {
  border-bottom: 0.15rem solid var(--main-color);
  max-width: 17rem;
  margin-bottom: 0.5rem;
  letter-spacing: 0.05rem;
}
#cv .projects-card__body ul {
  padding-left: 1.5rem;
}
#cv .projects-card__body ul li {
  margin-bottom: 0.2rem;
}
#cv .projects-card__body mark {
  background-color: rgba(255, 255, 255, 0);
  color: var(--main-color);
  font-weight: bold;
}
#cv .projects-card__body__imgcontainer {
  text-align: center;
}
#cv .projects-card__body__img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: bottom;
     object-position: bottom;
  border-radius: 0.5rem;
}
#cv .projects-card__body__img--mobile {
  width: 100%;
  max-width: 18rem;
}
@media (min-width: 801px) {
  #cv .projects-card__body__img--mobile {
    max-width: 22rem;
  }
}
#cv .projects-card__body__img--pc {
  width: 100%;
  max-width: 30rem;
}
@media (min-width: 801px) {
  #cv .projects-card__body__img--pc {
    max-width: 45rem;
  }
}
#cv .projects-card__body__imgdesc {
  margin-top: 0.5rem;
  text-align: center;
  font-style: italic;
}
#cv .projects-card__body__imgbtn {
  text-align: center;
  margin-top: 1rem;
}
#cv .projects-card__body__imgbtn button {
  height: 2.5rem;
  padding: 0rem 0.7rem;
  background-color: var(--second-color);
  color: var(--main-color);
  font-size: 2.1rem;
  border: none;
}
#cv .projects-card__body__imgbtn button:hover {
  cursor: pointer;
  opacity: 0.5;
}
#cv .projects-card__body__imgbtn span {
  margin: 0 1.2rem;
  font-size: 1.2rem;
  position: relative;
  top: -0.4rem;
}
#cv .projects-card__icons {
  font-size: 1.3rem;
  margin: 0.5rem 0;
}
#cv .projects-card__expand-btn {
  position: absolute;
  bottom: 0.75rem;
  height: 1.9rem;
  width: calc(100% - 1.5rem);
  padding-top: 0.15rem;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  text-align: center;
}
#cv .projects-card__expand-btn:hover {
  cursor: pointer;
}
#cv .projects-card__expand-btn--close {
  color: var(--main-color);
}
#cv .projects-card__expand-btn span {
  position: relative;
  top: -0.35rem;
  padding-left: 0.3rem;
}
#cv .projects-card__expand-btn svg {
  font-size: 1.4rem;
}
#cv .experience {
  background-color: var(--second-color);
}
#cv .experience .layout-child__left {
  color: var(--main-color);
}
#cv .experience .layout-child__left__line {
  background-color: var(--main-color);
}
#cv .experience .layout-child__left__image img {
  scale: 0.9;
}
#cv .experience .layout-child__right {
  color: var(--text-color);
}
#cv .experience__container {
  width: 100%;
  flex-direction: column;
}
#cv .experience__container h3 {
  font-size: 1.8rem;
  color: var(--main-color);
  font-weight: normal;
  margin-bottom: 1.2rem;
  margin-left: -0.1rem;
}
#cv .experience__container__title {
  font-weight: bold;
  font-size: 1.1rem;
  letter-spacing: 0.03rem;
}
#cv .experience__container__summary {
  margin: 0.3rem 0 0.6rem 0;
  font-size: 1rem;
}
@media (max-width: 800px), (max-height: 400px) {
  #cv .experience__container__summary {
    font-size: 0.9rem;
  }
}
@media (max-width: 500px) {
  #cv .experience__container__summary {
    font-size: 0.8rem;
  }
}
#cv .experience__container div {
  font-size: 0.9rem;
  font-style: italic;
  opacity: 0.7;
}
#cv .experience__container svg {
  display: block;
  position: relative;
  left: 0rem;
  margin: 0.4rem 0;
  font-size: 1.8rem;
  color: var(--main-color);
}
#cv .contact {
  background-color: var(--main-color);
}
#cv .contact .layout-child__left {
  color: var(--second-color);
}
#cv .contact .layout-child__left__line {
  background-color: var(--second-color);
}
@media (min-width: 801px) {
  #cv .contact .layout-child__left__image {
    margin-top: 10px;
  }
}
#cv .contact__right {
  color: rgb(247, 247, 247);
}
#cv .contact__right p:not(p:first-of-type):not(p:last-of-type) {
  margin: 1rem 0;
}
#cv .contact__right a {
  text-decoration: underline;
}
#cv .contact__right svg {
  margin-top: 7px;
  font-size: 3rem;
  color: rgb(10, 102, 194);
  background-color: white;
  border-radius: 0.3rem;
}