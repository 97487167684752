*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

a{
  text-decoration: none;
  color: inherit;
}

/* Smooth scroll buggar på firefox för mobilen så la till detta. */
@-moz-document url-prefix() {
  @media (pointer: coarse) {
    scroll-behavior: auto;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  scroll-behavior: auto;
}